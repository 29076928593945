h1.page-title {
    font-size: 2.7rem;
    color: #0059A0;
    font-weight: bold;
    font-family: "Noto Serif TC", sans-serif;
    margin-bottom: .2em;
    display: flex;
    align-items: center;
    justify-content: center;
}
h2.page-sub_title {
    font-size: 1.3em;
    line-height: 2em;
    color: #000;
    text-transform: capitalize;
}
h1.page-title:before {
    margin: 5px 15px 0;
    content: '';
    width: 35px;
    height: 35px;
    background-repeat: no-repeat;
    background-image: url(/img/icon/title-bg.png);
}
h1.page-title:after {
    margin: 5px 0 0 15px;
    content: '';
    width: 35px;
    height: 35px;
    background-image: url(/img/icon/title-bg.png);
    background-repeat: no-repeat;
}

.navbar .logo {
    width: 105px;
    height: 49px;
    background-image: url(/img/logo.png);
}
.navbar.custom_cls .logo {
    background-position: 0 -49px;
}

.dropdown-item {
    font-weight: 300;
}
.nav-item.dropdown a.dropdown-toggle {
    padding: 0 5px;
}
.nav-item.dropdown a {
    display: inline-block;
}
.dropdown-toggle {
    margin-left: -.7rem;
}
.m-fixed-bar {
    display: none;
}

.blog_single_dv {
    margin-bottom: 30px;
}
.editor {
    margin-bottom: 50px;
}
.with-border {
    padding: 40px 20px;
    border: 1px solid #999;
}
.editor img {
    padding: 20px 0;
}
.editor p {
    font-size: 18px;
    font-weight: 300;
    color: #666;
    line-height: 2;
    margin-bottom: 20px;
}
.editor blockquote p {
    color: #fff;
    text-align: justify;
}
.editor blockquote p:last-child {
    margin: 0;
}
.editor .lprt {
    margin-top: 30px;
    margin-bottom: 30px;
}
.with-border .editor .lprt {
    margin: 30px 0;
}
.editor h4 {
    font-weight: 500;
    font-size: 26px;
    letter-spacing: 1px;
    line-height: 1.5;
    margin-bottom: 10px;
}

.editor h5 {
    font-weight: 300;
    font-size: 20px;
    color: #666;
    margin-bottom: 10px;
    line-height: 1.5;
}

.editor li:before {
    content: '．';
    display: inline-flex;
    font-size: 20px;
    color: #666;
    font-weight: 300;
    align-items: center;
    line-height: 1.5;
}
.editor ul {
    margin-bottom: 20px;
}
.editor li {
    display: block;
    font-size: 20px;
    color: #666;
    font-weight: 400;
    align-items: center;
    line-height: 1.5;
}

section.about h5 {
    text-transform: uppercase;
    font-weight: 500;
    color: #00A0EA;
    font-size: 31px;
    padding-bottom: 6px;
}
section.about p {
    font-size: 20px;
    font-weight: 300;
    line-height: 2.5;
}
.testimonial3_page h5 {
    font-weight: 500;
    font-size: 32px;
}
.who_we_title ul {
    margin-bottom: 40px;
}
ul.pagination {
    justify-content: center;
}
.post_pagination {
    margin-top: 50px;
}
.what_img_blank {
    padding: 35px 10px;
    color: #fff;
}
.what_img_blank h4 {
    color: #fff;
    font-size: 28px;
    line-height: 2;
    margin-bottom: 20px;
    font-weight: 500;
}
.what_img_blank p {
    line-height: 1.5;
    font-size: 17px;
    letter-spacing: 1px;
    font-weight: 300;
}

.what_img_blank .btn {
    margin-top: 40px;
}
.navbar.custom_cls .navbar-nav .nav-item .nav-link,
.navbar.custom_cls .navbar-nav .nav-item .dropdown-toggle {
    color: #fff;
}
.floating-bar {
    position: fixed;
    bottom: 15%;
    right: 20px;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.bredcrumb {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 90px;
}
.bredcrumb ul li {
    display: inline-block;
    color: #a0a0a0;
    padding: 0 10px;
    position: relative;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: 300;
}

.bredcrumb ul li:not(:last-child):after {
    content: ">";
    right: -6px;
    position: absolute;
}
@media (max-width: 480px) {
    #return-to-top {
        bottom: 60px;
    }
    .broadcamp_hdr {
        padding-top: 0;
    }
    .single_testi p.content {
        padding: 0;
    }
    .single_testi span {
        padding-left: 0;
    }
    .floating-bar {
        transform: scale(.7);
        right: -5px;
    }
    .bredcrumb {
        margin-top: 20px;
    }
    .services_home2 {
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .sidebar_widget_blog .categories_s {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        flex-direction: column;
    }
    .m-fixed-bar {
        display: block;
        position: fixed;
        left: 0;
        bottom: 0;
        z-index: 10;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .m-fixed-bar ul {
        width: 100%;
        height: auto;
    }
    .m-fixed-bar ul li {
        display: inline-block;
        float: left;
        width: 50%;
        height: 45px;
        text-align: center;
        line-height: 45px;
        font-size: 16px;
        font-weight: 500;
    }
    .m-fixed-bar ul li a {
        color: #fff;
    }
    .m-fixed-bar ul li:first-child {
        background-color: #00a0e9;
    }
    .m-fixed-bar ul li:last-child {
        background-color: #1de30e;
    }
}